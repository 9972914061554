.aj-font-heading1 {
    /* H1_80 */

    /*font-family: SF Pro Display;*/
    font-style: normal;
    font-weight: bold;
    font-size: 80px;
    line-height: 95px;

    /*!* Text Black *!*/
    /*color: #181414;*/

}

.aj-font-heading2 {
    /* Heading 2 (60) */

    /*font-family: SF Pro Display;*/
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 72px;

    /* Text Black */
    /*color: #181414;*/

}

.aj-font-heading3 {

    /* Heading 3 (30) */
    /*font-family: SF Pro Display;*/
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    /* identical to box height */

    /* Text Black */
    /*color: #181414;*/

}

.aj-font-heading4 {
    /* Heading 4 (24) */

    /*font-family: SF Pro Display;*/
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */

    /* Text Black */
    /*color: #181414;*/

}

.aj-font-body-large {
    /* Large Text (24) */

    /*font-family: SF Pro Display;*/
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */

    /* Text Black */
    /*color: #181414;*/

}

.aj-font-body-large-semibold {
    /* Large Text Semibold (24) */

    /*font-family: SF Pro Display;*/
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */

    /* Text Black */
    /*color: #181414;*/

}

.aj-font-body-medium {
    /* Medium Text (20) */
    /*font-family: SF Pro Display;*/
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */

    /* Text Black */
    /*color: #181414;*/

}

.aj-body-caption {
    /* Caption (18) */

    /*font-family: SF Pro Text;*/
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;

    /* Text Black */
    /*color: #181414;*/

}

.aj-font-body-normal {
    /* Normal Text (16) */
    /*font-family: SF Pro Text;*/
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    /* Text Black */
    /*color: #181414;*/

}

.aj-font-body-small {
    /* Small Text (12) */
    /*font-family: SF Pro Text;*/
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;

    /* Text Black */
    /*color: #181414;*/

}

.aj-font-body-medium-semibold {
    /* Medium Text Semibold (20) */
    /*font-family: SF Pro Display;*/
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */

    /* Text Black */
    /*color: #181414;*/
}

.aj-font-body-caption-semibold {
    /* Caption Semibold (18) */
    /*font-family: SF Pro Text;*/
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;

    /* Text Black */
    /*color: #181414;*/

}

.aj-font-body-normal-semibold {

    /* Normal Text Semibold (16) */
    /*font-family: SF Pro Text;*/
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    /* Text Black */
    /*color: #181414;*/

}

.aj-font-body-small-semibold {
    /* Small Text Semibold (12) */
    /*font-family: SF Pro Text;*/
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;

    /* Text Black */
    /*color: #181414;*/

}
