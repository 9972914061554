.create-account {
    width: 100%;
}


.create-account-container {
    padding: 68px 240px 68px 87px;
}

.create-account-container .create-account-input-group {
    /*background: black;*/
    /*padding-left: 90px;*/
    /*padding-right: 180px;*/
    padding-top: 55px;
    /*height: 80px;*/
}

.create-account-container .create-account-close {
    width: 20px;
    height: 20px;

    padding-left: 914px;
    /*display: inline-block;*/
}

.create-account-container .create-account-prompt {
    /*width: 304px;*/
    height: 53px;

    /* Heading 3 (30) */

    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;

    /* Secondary */

    color: #8F8F8F;

    /*padding-left: 87px;*/
}

.create-account-container .create-account-separate-line {
    width: 303px;
    height: 0px;
    /* Base */

    border: 1px solid #BDBDBD;
}
.create-account-container .create-account-upload-icon
{
    /*background: #BDBDBD;*/
    cursor: pointer;

}

.create-account-container .create-account-agree {
    height: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    display: inline-block;
    /* Base */

    color: #BDBDBD;
}

