.selectable-icon {
    position: relative;
    width: 60px;
    height: 60px;
}
.selectable-icon-container {
    position: absolute;
    top: 3px;
    left: 3px;
    z-index: 12;
}

.selectable-icon-container img {
    width: 53px;
    height: 53px;
}

/*.selectable-icon-container img:hover {*/
/*    width: 60px;*/
/*    height: 60px;*/
/*}*/

/*.selectable-icon-container :hover {*/
/*    width: 62px;*/
/*    height: 62px;*/
/*}*/

.selectable-icon-container-hover {
    position: absolute;
    z-index: 10;
    opacity: 0.4;

    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
}
.selectable-icon-container-hover img{
    width: 60px;
    height: 60px;
}

.selectable-icon-container .content-img {
}

.selected-icon-container {
    position: absolute;

    width: 59px;
    height: 59px;

    /*top: 2px;*/
    /*left: 2px;*/

    /* Text Light Color */

    /*background: #A79FBD;*/

    border-radius: 3px;
    border: solid 3px #A79FBD;

    z-index: 100;
}

.selected-icon-container .selected-icon {
    position:absolute;
    top: 0;
    left: 0;

    width: 53px;
    height: 53px;

    background: rgba(255, 255, 255, 0.8);
}

.selected-icon img {
     width: 30px;
     height: 23px;
 }