.ajupload {
    width: 771px;
    /*height: 342px;*/

    background: #FFFFFF;
    /* DndCard Shadow 2 */

    box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.25);
    border-radius: 22px;

    overflow:hidden;

    position: relative;
}

.ajupload .ajupload-container {

    margin: 36px;

    /*border: 2px dashed #E5E5E5;*/
    /*box-sizing: border-box;*/
    /*border-radius: 20px;*/
}

.ajupload .ant-upload.ant-upload-drag {
    background-color: #FFFFFF;
    border: 2px dashed #E5E5E5;
    box-sizing: border-box;
    border-radius: 20px;
}

.ajupload .ajupload-title {
    /*width: 525px;*/
    height: 33px;

    /* Heading 4 (24) */

    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-align: center;

    /* Secondary */

    color: #8F8F8F;
}

.ajupload .ajupload-description {

    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;

    /* Base */

    color: #BDBDBD;
}

.avatar-uploader  .ant-upload {
    width: 215px;
    height: 127px;
    padding: 0 !important;
}

.avatar-uploader .ant-upload.ant-upload-select-picture-card {
    border-radius: 0 !important;
}

.image-uploader .ant-upload {
    width: 570px;
    height: 375px;
    padding: 0 !important;
}

.image-uploader .ant-upload.ant-upload-select-picture-card {
    border-radius: 0 !important;
}