.dnd-card {
    /*background: #6A35FF;*/
    /*!* Purple *!*/

    /*border: 2px solid #6A35FF;*/
    /*box-sizing: border-box;*/
    /*border-radius: 21px;*/

    /*padding: 0.5rem 1rem;*/
    /*margin-bottom: 14px;*/
    cursor: move;

    display: inline-block;

    /*height: 33px;*/
    background: 0;

}

.dnd-card-index {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;

    /* Base */

    color: #181414;
    display: inline-block;
    vertical-align: middle;
}

.dnd-card .dnd-card-text {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;

    /* Text White */

    color: #FFFFFF;
    display: inline-block;
    background: #754FDE;
    /* Purple */

    border: 2px solid #754FDE;
    box-sizing: border-box;
    border-radius: 21px;

    padding: 5px 1rem;
    margin-top: 7px;
    margin-bottom: 7px;

    /*height: 100%;*/

}