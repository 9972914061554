.dnd-container {
    /*width: 400px;*/
    /*height: 722px;*/
    /*background: #FFFFFF;*/
    /* Card Shadow 2 */
    width: 100%;
    height: 100%;
    box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.25);
    border-radius: 44px;
    /*background: none;*/

    position: absolute;
}

.dnd-container .dnd-container-title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;

    /* Secondary */

    color: #8F8F8F;

    padding-left: 44px;
}

.dnd-container .dnd-container-sub-title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    /* Base */

    color: #BDBDBD;

    padding-left: 44px;
    padding-right: 44px;

}


.dnd-container .dnd-container-context {
    padding-left: 40px;
    display: inline-block;

}

.dnd-container .dnd-container-index {
    /*padding-left: 48px;*/
    display: inline-block;
}

.dnd-container .dnd-container-index-text {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;

    /* Base */

    color: #BDBDBD;
    /*display: inline-block;*/

    height: 52.6px;

    /*padding-top: 4px;*/
}

.dnd-container .dnd-container-button {
    position: absolute;

    left: 72px;
    bottom: 56px;

    width: 100%
}