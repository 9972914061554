.pick-values {
    position: relative;
    width: 1170px;
    min-height: 260px;
    /*height: 260px;*/

    /* Text White */

    background: #FFFFFF;
    /* DndCard Shadow 2 */

    box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
}

.pick-values .pick-values-container {
    padding: 25px 30px;
}

.pick-values .pick-values-title {
    font-family: ManropeRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
    /* identical to box height */


    /* Base */

    color: #BDBDBD;

    text-align: center;

    /*width: 760px;*/
    /*height: 100%;*/
}

.pick-values .pick-values-tag-container {
    /*padding: 25px 30px;*/
    /*margin: auto;*/
    /*display: inline-block;*/
    text-align: center;
    /*padding-left: 99px;*/
    /*padding-right: 99px;*/
}

.pick-values .pick-values-icons-container {
    text-align: center;
    padding-left: 90px;
    padding-right: 90px;

}

.pick-values .pick-values-foot {
    position: absolute;
    bottom: 22px;
    right: 25px;
    font-family: ManropeLight;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */


    /* Base */

    color: #BDBDBD;
    text-align: right;
}

.pick-values .pick-values-tips {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: flex-end;
    text-align: center;
    width: 100%;
    /* Grey */

    color: #A0A0A0;
    text-align: center;
}