.default-input-label {
    height: 19px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #BDBDBD;
    display: inline-block;
}

.default-input {
    height: 19px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    border: 0;
    width: 100%;
}

.default-input:focus {
    outline: none;
}

.default-input-icon {
    width: 16px;
    height: 16px;
    background: #BDBDBD;
}

.default-input-separator {
    height: 0px;
    width: 100%;
    /* Base */

    /* TODO change border to border-bottom for release*/

    border: 1px solid #BDBDBD;
}

input::-webkit-input-placeholder{ /*WebKit browsers*/
    color: #BDBDBD;
}
input::-moz-input-placeholder{ /*Mozilla Firefox*/
    color: #BDBDBD;
}
input::-ms-input-placeholder{ /*Internet Explorer*/
    color: #BDBDBD;
}
