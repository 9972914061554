.aj-btn {

}

.aj-btn .ant-btn-round {
    border-radius: 30px
}

.aj-btn .ant-btn-round.ant-btn-sm {
    border-radius: 20px
}

.aj-filter-btn {

}

.aj-btn .aj-filter-btn .ant-btn-round {
    border-radius: 50px
}


