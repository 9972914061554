.login-common-page {
    width: 969px;
    height: 623px;
    background: #FFFFFF;
    box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.25);
    border-radius: 44px;
}

.login-common-container {
    padding: 74px 90px 60px;
    height: 100%;
}

.login-common-title {
    font-family: ManropeSemiBold;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    color: #181414;
    white-space: pre-line;
}

.login-common-sub-title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #A0A0A0;
}

.login-common-description {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #A0A0A0;
}

.login-common-back-button-container {
    width: 186px;
    height: 60px;
    border: 2px solid #A79FBD;
    box-sizing: border-box;
    border-radius: 30px;
}

.login-common-container-body {
    min-height: 300px;
    width: 100%;
    position: relative;
}

.login-common-container-footer {
    position: absolute;
    height: 60px;
    padding-left: inherit;
    padding-right: inherit;
    left: 0;
    right: 0;
    bottom: 60px;
}

.login-common-back-button-text {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: center;
    height: 100%;
    color: #A79FBD;
}

.login-common-next-button-container {
    background: #A79FBD;
    border: 2px solid #A79FBD;
    box-sizing: border-box;
    border-radius: 30px;
    height: 60px;
    width: 280px;
}

.login-common-next-button-text {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    height: 100%;
}
