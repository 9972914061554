.ajmodal-default  {
    position: fixed;
    top: 0;
    left: 0;

    /*background: yellow;*/
    /* DndCard Shadow 2 */

    /*box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.25);*/
    /*border-radius: 44px;*/

    /*position: relative;*/
}

.ajmodal-default-mask {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    /*background: linear-gradient(180deg, rgba(222, 219, 220, 0.45) 38.54%, rgba(148, 148, 148, 0) 98.44%);*/
    background-color: rgba(0, 0, 0, 0.45);
}