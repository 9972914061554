.ajcheckbox-container {

}

.ajcheckbox-container .default-ajcheckbox-icon-container {
    width: 16px;
    height: 16px;
    bottom: 5px;
    display: inline-block;
    position: relative;
    vertical-align: bottom;

}

.ajcheckbox-container .default-ajcheckbox-icon-container img {
    position: absolute;
    width: 16px;
    height: 16px;
}

.ajcheckbox-container .default-ajcheckbox-text {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 19px;

    /* Base */

    color: #6E6E6E;

    display: inline-block;
    vertical-align: bottom;
}