
.start-footer {
    /*position: absolute;*/
    /*bottom: 0;*/
    /*left: 0;*/
    /*width: 100vw;*/
    height: 292px;
    /*background: #474A63;*/
    background: #000000;
    font-family: InterSemiBold;
}

.start-footer-container {
    padding: 52px 60px 0 60px;
}

.start-footer-container .start-footer-title {
    font-style: normal;
    font-weight: 500;
    font-size: 33px;
    line-height: 39px;
    /* identical to box height */

    /*text-align: center;*/

    /* Text White */

    color: #FFFFFF;
}

.start-footer-container .start-footer-menu {
    font-family: InterSemiBold;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */


    /* Text White */

    color: #FFFFFF;
}

.start-footer-container .start-footer-company {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;

    /* Text White */

    color: #FFFFFF;
    text-align: right;

}

.start-footer .start-footer-clickable {
    cursor: pointer;
}