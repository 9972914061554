.button-container {
    cursor: pointer;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -khtml-user-select: none;
    user-select: none;
}


.button-container .button-container-disable {
    cursor : not-allowed;
    /*pointer-events:none;*/
}


.button-container .default-button-container {
    background: #A79FBD;
    /* Text Light Color */

    border: 2px solid #A79FBD;
    box-sizing: border-box;
    border-radius: 30px;

    width: 303px;
    height: 60px;
}

.button-container .default-button-text {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    /*display: flex;*/
    align-items: center;
    text-align: center;
    color: #FFFFFF;

    height: 100%;
}


.button-container .default-button-container-reverse {
    background: #FFFFFF;
    border: 2px solid #A79FBD;
    box-sizing: border-box;
    border-radius: 30px;

    width: 303px;
    height: 60px;

}

.button-container .default-button-text-reverse {
    color: #A79FBD;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    /*display: flex;*/
    align-items: center;
    text-align: center;

    height: 100%;
}
